import { useRef, ReactNode, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { clsx } from 'clsx'

import {
  CaretDownIcon,
  CaretUpIcon,
  InformationCircleIcon,
  Tooltip,
} from '@tovala/component-library'

import { getEnvVar } from 'utils/env'
import PaginationTableFooter from 'components/common/PaginationTableFooter'
import {
  getMostRecentThingEvents,
  OvenEvent,
  getAuthToken,
} from 'utils/oatsApi'
import { LoadingIcon, ExclamationCircleIcon } from 'components/common/Icons'
import { convertToPrettyString } from 'utils/stringUtils'
import useWebsocket from 'utils/socketConnection'

function parseLiveEvent(event: string): OvenEvent[] {
  try {
    const parsed = JSON.parse(event)
    if (typeof parsed.deviceID !== 'string') {
      throw new Error('Invalid deviceID')
    }

    if (typeof parsed.receiveTimeMs !== 'number') {
      throw new Error('Invalid receiveTimeMs')
    }

    if (typeof parsed.eventType !== 'string') {
      throw new Error('Invalid eventType')
    }

    if (typeof parsed.eventKey !== 'string') {
      throw new Error('Invalid eventKey')
    }

    if (typeof parsed.eventTimeMs !== 'number') {
      throw new Error('Invalid eventTimeMs')
    }

    parsed.isLive = true
    parsed.payload = JSON.parse(parsed.payload)

    return [parsed]
  } catch {
    return []
  }
}

type CookingEventPayload = { cookCycleID: string; barcode: string }
type BootupEventPayload = { reason: string }
type MqttConnectedEventPayload = { sessionid: string }
type BarcodeScannedEventPayload = { barcode: string }
type OTAEventPayload = { firmwareVersion: string }
type DoorChangeEventPayload = { doorState: string }
type StateChangeEventPayload = { fromState: string; toState: string }
type TemperatureEventPayload = {
  chamberTemperature: number
  barrelTemperature: number
}
type WifiEventPayload = { rssi: number }

const OvenEventViewer = ({ ovenId }: { ovenId: string }) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [filter, setFilter] = useState<string>('')
  const [chunks, setChunks] = useState<OvenEvent[][]>([])

  // Keep a good amount of them in memory at once
  const chunkSize = 10000

  // Since we're grabbing 1000 at a time, the page key should only update
  // when we're trying to query up past 1000 events, or near to it
  const pageKey = Math.floor((pageSize * (page + 1) + 100) / chunkSize)

  const {
    isFetching,
    isPreviousData,
    error: fetchError,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['oven-events', ovenId, pageKey],
    queryFn: async () => {
      if (!ovenId) {
        throw new Error('No thing name provided')
      }

      const lastChunk = pageKey > 0 ? chunks[pageKey - 1] : []
      const before =
        lastChunk.length > 0 ? lastChunk[lastChunk.length - 1].receiveTimeMs : 0
      const chunkEvents = await getMostRecentThingEvents(
        ovenId,
        before,
        chunkSize,
      )

      setChunks((chunks) => {
        return chunks.slice(0, pageKey).concat([chunkEvents])
      })

      return []
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const { data: liveEventStream } = useWebsocket<OvenEvent>(
    'events',
    `${getEnvVar('OATS_API_URL')}/api/oven/events/${ovenId}/stream?token=${getAuthToken()}`,
    parseLiveEvent,
    { enabled: page === 0 },
  )

  // De-duplicate live events and sort them by time descending
  const liveEvents = Object.values(
    (
      liveEventStream.sort((a, b) => b.eventTimeMs - a.eventTimeMs) || []
    ).reduce((acc, n) => ({ ...acc, [n.eventTimeMs]: n }), {}),
  ) as OvenEvent[]

  if (page > 0) {
    liveEvents.forEach((event: OvenEvent) => {
      event.isLive = false
    })
  }

  const eventHistory = chunks.reduce((acc, chunk) => acc.concat(chunk), [])
  const numTotalEvents = liveEvents.length + (eventHistory || []).length

  // Return text that could be filtered for a particular event type
  const filterTerm = (event: OvenEvent): string => {
    const cookingFilterTerms = (p: CookingEventPayload) => {
      const parts = (p.barcode || '').split('|')
      return `${p.cookCycleID} ${parts ? [1] : ''}}`
    }

    switch (event.eventKey) {
      case 'cookingStarted':
      case 'cookingComplete':
      case 'cookingEnded':
      case 'cookingStatusUpdate':
      case 'cookingCanceled':
      case 'preheatStarted':
      case 'preheatComplete':
        return cookingFilterTerms(event.payload as CookingEventPayload)
      case 'bootup-complete':
        return (event.payload as BootupEventPayload).reason
      case 'mqttConnected':
        return (event.payload as MqttConnectedEventPayload).sessionid
      case 'barcodeScanned':
        return (event.payload as BarcodeScannedEventPayload).barcode
      case 'otaReceived':
      case 'otaSuccessful':
      case 'otaFailed':
        return (event.payload as OTAEventPayload).firmwareVersion
      default:
        return ''
    }
  }

  const filteredEvents =
    // Only prepend live events while watching first page
    (page === 0 ? liveEvents : []).concat(eventHistory).filter((event) => {
      if (filter === '') {
        return true
      }
      if (event.eventKey === undefined || event.eventType === undefined) {
        return false
      }

      const orTerms = filter
        .split(/\s+OR\s+|,/i)
        .map((e) => e.trim().toLocaleLowerCase())
        .filter((e) => e !== '' && e !== ',')

      return orTerms.some((orTerm) => {
        const filterParts = orTerm.split(/\s+/).filter((e) => e !== '')
        const negateTerms = filterParts
          .filter((part) => part[0] === '-')
          .map((part) => part.slice(1))
        const includeTerms = filterParts.filter((part) => part[0] !== '-')

        const filterTerms = [event.eventKey, event.eventType, filterTerm(event)]
          .filter((e) => e !== '')
          .map((e) => e.toLocaleLowerCase())
        return (
          includeTerms.every((part) => {
            return filterTerms.some((t) => {
              return part.length < 3 || t.includes(part)
            })
          }) &&
          negateTerms.every((part) => {
            return part.length < 3
              ? true
              : !filterTerms.some((t) => {
                  return t.includes(part)
                })
          })
        )
      })
    })

  const pageEvents = filteredEvents.slice(
    page * pageSize,
    (page + 1) * pageSize,
  )

  const numFilteredEvents =
    filteredEvents.length + (page !== 0 ? liveEvents : []).length
  const filterRef = useRef<HTMLInputElement>(null)

  const B = ({ children }: { children: ReactNode[] | ReactNode }) => (
    <span className="text-nowrap bg-gray-500 px-1 font-bold font-mono">
      {children}
    </span>
  )

  const downloadEvents = () => {
    const url = URL.createObjectURL(
      new Blob(
        filteredEvents.map((e) => JSON.stringify(e) + '\n'),
        { type: 'application/json' },
      ),
    )
    window.open(url)
  }

  const moreEvents = (page + 1) * pageSize - 1 < numFilteredEvents

  const Pager = () => (
    <PaginationTableFooter
      hasMore={moreEvents}
      isLoading={isFetching || isPreviousData}
      onChangePage={setPage}
      onChangeRowsPerPage={setPageSize}
      page={page}
      rowsPerPage={pageSize}
      withReset={true}
    />
  )
  return (
    <div className="rounded-lg w-full overflow-clip bg-slate-50 shadow-lg border border-grey-3">
      <div className="gmd:flex">
        <form
          className="flex gap-2 flex-grow"
          onSubmit={(e) => {
            e.preventDefault()
            setFilter(filterRef.current?.value || '')
            setPage(0)
          }}
        >
          <div className="relative flex-grow my-2">
            <input
              ref={filterRef}
              className="font-mono rounded-lg border w-full p-2 mx-2"
              name="filter"
              placeholder="Filter events..."
              type="text"
            />
            <div className="text-sm text-gray-400 absolute right-0 bottom-0 p-2 flex items-center">
              {numFilteredEvents < numTotalEvents ? (
                <span>
                  {numFilteredEvents} / {numTotalEvents}
                </span>
              ) : (
                <span>{numTotalEvents}</span>
              )}

              <Tooltip
                trigger={
                  <span className="w-6 ml-2 cursor-help">
                    <InformationCircleIcon />
                  </span>
                }
              >
                Filter events by event type or event key. Use <B>OR</B> or{' '}
                <B>,</B> to separate multiple filters. Prefix terms with{' '}
                <B>-</B> to exclude events.
              </Tooltip>
            </div>
          </div>
          <button
            className="text-white py-0 m-2 px-2 bg-orange-1 rounded-lg"
            onClick={(e) => {
              e.preventDefault()
              downloadEvents()
            }}
            type="button"
          >
            Export
          </button>
        </form>
        <Pager />
      </div>
      <div className="overflow-auto relative min-h-[400px]">
        {isFetching && (
          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center text-l padding-20 gap-2">
            <LoadingIcon />
            <span>Loading...</span>
          </div>
        )}
        {fetchError ? (
          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center text-l padding-20 gap-2">
            <span className="w-8 text-red">
              <ExclamationCircleIcon />
            </span>
            <span>{(fetchError as Error).message}</span>
          </div>
        ) : (
          <></>
        )}
        <table className="table-fixed w-full">
          <thead className="bg-orange-1 text-white">
            <tr>
              <th className="p-2 px-4 md:px-1 text-left w-[20%] lg:w-[32%]">
                Class
              </th>
              <th className="p-2 px-4 md:px-1 text-left w-[25%] lg:w-[33%]">
                Event
              </th>
              <th className="p-2 px-4 md:px-1 text-left w-[25%] lg:w-[32%]">
                <Tooltip
                  trigger={
                    <div
                      className="flex items-center gap-1"
                      style={{ width: 'max-content' }}
                    >
                      <div>Time</div>
                      <div className="w-4">
                        <InformationCircleIcon />
                      </div>
                    </div>
                  }
                >
                  Time of the event in your local timezone
                </Tooltip>
              </th>
              <th className="w-[25%] lg:w-0 p-0"></th>
              <th className="md:w-0"></th>
            </tr>
          </thead>
          <tbody>
            {pageEvents &&
              !isFetching &&
              !fetchError &&
              pageEvents.map((event: OvenEvent, indx) => (
                <OvenEventRow
                  key={`${event.eventKey}/${event.receiveTimeMs}/${event.eventTimeMs}`}
                  event={event}
                  index={indx}
                />
              ))}
          </tbody>
        </table>
      </div>
      <Pager />
    </div>
  )
}

const OvenEventRow = ({
  event,
  index,
}: {
  event: OvenEvent
  index: number
}) => {
  const [dropdownState, setDropdownState] = useState<boolean>(false)

  if (!event.payload || Object.keys(event.payload).length === 0) {
    return (
      <tr
        className={clsx({
          'bg-slate-50': index % 2 === 0,
          'bg-white': index % 2 !== 0,
        })}
      >
        <td className="py-2">
          <span className="px-4 md:px-1">{event.eventType}</span>
        </td>
        <td className="py-2">
          <span className="px-4 md:px-1">
            {convertToPrettyString(event.eventKey)}
          </span>
        </td>
        <td className="py-2">
          <span className="px-4 md:px-1">
            {' '}
            {new Date(event.eventTimeMs).toLocaleString()}
          </span>
        </td>
        <td></td>
        <td></td>
      </tr>
    )
  }

  // Call out interesting details depending on the event type
  const EventDetail = () => {
    const stateChange = (p: StateChangeEventPayload) => (
      <span>
        {p.fromState} <>&#x2192;</> {p.toState}
      </span>
    )
    const cookCycle = (p: CookingEventPayload) => <span>{p.cookCycleID}</span>
    const temperature = (p: TemperatureEventPayload) => (
      <span>
        C={p.chamberTemperature}°F B={p.barrelTemperature}°F
      </span>
    )
    const bootupComplete = (p: BootupEventPayload) => <span>{p.reason}</span>
    const wifiConnected = (p: WifiEventPayload) => <span>RSSI {p.rssi}</span>
    const mqttConnected = (p: MqttConnectedEventPayload) => (
      <span>{p.sessionid}</span>
    )
    const barcodeScanned = (p: BarcodeScannedEventPayload) => (
      <span>{p.barcode}</span>
    )
    const doorChange = (p: DoorChangeEventPayload) => <span>{p.doorState}</span>
    const ota = (p: OTAEventPayload) => <span>{p.firmwareVersion}</span>

    switch (event.eventKey) {
      case 'ovenStateChange':
        return stateChange(event.payload as StateChangeEventPayload)
      case 'cookingStarted':
      case 'cookingComplete':
      case 'cookingEnded':
      case 'cookingStatusUpdate':
      case 'cookingCanceled':
      case 'preheatStarted':
      case 'preheatComplete':
        return cookCycle(event.payload as CookingEventPayload)
      case 'temperature':
        return temperature(event.payload as TemperatureEventPayload)
      case 'bootup-complete':
        return bootupComplete(event.payload as BootupEventPayload)
      case 'wifiConnected':
        return wifiConnected(event.payload as WifiEventPayload)
      case 'mqttConnected':
        return mqttConnected(event.payload as MqttConnectedEventPayload)
      case 'barcodeScanned':
        return barcodeScanned(event.payload as BarcodeScannedEventPayload)
      case 'doorChange':
        return doorChange(event.payload as DoorChangeEventPayload)
      case 'otaReceived':
      case 'otaSuccessful':
      case 'otaFailed':
        return ota(event.payload as OTAEventPayload)
      default:
        return <></>
    }
  }

  return (
    <>
      <tr
        className={clsx(
          'border-t border-lg border-gray-200 px-4 py-2 w-full cursor-pointer md:text-sm',
          {
            'bg-slate-50': index % 2 === 0,
            'bg-white': index % 2 !== 0,
            'animate-flash-in': event.isLive,
            'bg-yellow-901': event.isLive,
          },
        )}
        onClick={() => {
          setDropdownState(!dropdownState)
        }}
      >
        <td className="py-2 overflow-hidden">
          <div className="px-4 md:px-1 text-nowrap">{event.eventType}</div>
        </td>
        <td className="py-2 overflow-hidden">
          <div className="px-4 md:px-1 text-nowrap">
            {convertToPrettyString(event.eventKey)}
          </div>
        </td>
        <td className="py-2 overflow-hidden">
          <div className="px-4 md:px-1 text-nowrap">
            {new Date(event.eventTimeMs).toLocaleString()}
          </div>
        </td>
        <td className="py-2 overflow-hidden">
          <div className="px-4 md:px-1 text-nowrap">
            <EventDetail />
          </div>
        </td>
        <td className="py-4 overflow-hidden">
          <div className="w-4 box-content flex glg:float-right pr-2">
            {dropdownState ? <CaretDownIcon /> : <CaretUpIcon />}
          </div>
        </td>
      </tr>
      {dropdownState && (
        <tr
          className={clsx('border-t border-b border-gray-200 px-4 py-2', {
            'bg-gray-50': index % 2 === 0,
            'bg-white': index % 2 !== 0,
          })}
        >
          <td className="max-w-dvw" colSpan={4}>
            <div className="px-4 py-2">
              <table className="table-fixed border-lg border overflow-auto w-full">
                <tbody>
                  {Object.entries(event.payload).map(([key, value], indx) => (
                    <tr
                      key={indx}
                      className={indx % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                    >
                      <td className="py text-left font-bold px-2 w-[16em]">
                        {convertToPrettyString(key)}
                      </td>
                      <td className="py text-left px-2 overflow-hidden">
                        {typeof value === 'string'
                          ? value
                          : JSON.stringify(value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default OvenEventViewer
