import OvenSearch from './OvenSearch'

import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import TabinatedRenderer from 'components/common/TabinatedRenderer'
import { useState, useEffect } from 'react'
import {
  useFavorites,
  useNamedDevices,
  fetchHistory,
  searchForThings,
  APISearchResult,
  OvenData,
  ThingHistory,
} from 'utils/oatsApi'
import { CircleFilledIcon } from 'components/common/Icons'
import { stateColors } from './ConnectionState'
import OvenList from './OvenList'
import { Tooltip } from '@tovala/component-library'

const OvensPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchResults, setSearchResults] = useState<APISearchResult | null>(
    null,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [historyPage, setHistoryPage] = useState<number>(0)
  const [historyPageSize, setHistoryPageSize] = useState<number>(25)

  const [globalFavoritePage, setGlobalFavoritePage] = useState<number>(0)
  const [globalFavoritePageSize, setGlobalFavoritePageSize] =
    useState<number>(25)

  const [myFavoritePage, setMyFavoritePage] = useState<number>(0)
  const [myFavoritePageSize, setMyFavoritePageSize] = useState<number>(25)

  const search = searchParams.get('search') || ''
  const searchPage = parseInt(searchParams.get('page') || '1', 10)
  const searchPageSize = parseInt(searchParams.get('limit') || '25', 10)
  const activeTab = searchParams.get('tab') || 'history'
  const [stateFilter, setStateFilter] = useState<string[]>([
    'online',
    'offline',
  ])

  const [searchKey, setSearchKey] = useState<number>(1)
  const isNamedSearch = (key: string) => key.match(/^"(.+)"?$/)

  useEffect(() => {
    if (activeTab === 'search') {
      setSearchParams({
        tab: 'search',
        search,
        page: `${searchPage}`,
        limit: `${searchPageSize}`,
      })

      if (isNamedSearch(search)) {
        setSearchParams({
          tab: 'favorites',
          search,
          page: `${searchPage}`,
          limit: `${searchPageSize}`,
        })
      } else {
        executeOvenSearch(
          search.length > 2 ? search : '',
          searchPage,
          searchPageSize,
          setSearchResults,
          setIsLoading,
          stateFilter,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchPage, searchPageSize, activeTab, stateFilter])

  const { data: namedDevices, isLoading: globalFavoritesLoading } =
    useNamedDevices()

  const namedOvens = namedDevices?.namedOvens || []
  const ovenNames = namedDevices?.ovenNames || {}

  const { data: favorites, isLoading: favoritesLoading } = useFavorites()
  const myFavorites = favorites || []

  const queryOpts = {
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
  }

  const { data: allHistory, isLoading: historyLoading } = useQuery({
    queryKey: ['device-history'],
    queryFn: async () => {
      return fetchHistory(1, 100)
    },
    ...queryOpts,
  })

  async function executeOvenSearch(
    key: string,
    page: number,
    limit: number,
    cb: (result: APISearchResult) => void,
    setIsLoading: (loading: boolean) => void,
    stateFilter: string[],
  ) {
    setIsLoading(true)
    const results = await searchForThings({ key, page, limit, stateFilter })
    cb(results)
    setIsLoading(false)
  }

  const updateTab = (tab: string) => {
    searchParams.set('tab', tab)
    setSearchParams(searchParams, { replace: true })
  }

  const setSearch = (key: string) => {
    const params = new URLSearchParams()

    params.set('search', key)
    params.set('page', '0')
    params.set('limit', `${searchPageSize}`)
    params.set('tab', isNamedSearch(key) ? 'favorites' : 'search')
    setSearchKey(searchKey + 1)
    setSearchParams(params)
  }

  const setSearchPage = (next: number | ((page: number) => number)) => {
    const nextValue = typeof next === 'function' ? next(searchPage) : next
    searchParams.set('page', `${nextValue}`)
    setSearchParams(searchParams)
  }

  const setSearchPageSize = (next: number | ((pageSize: number) => number)) => {
    const nextValue = typeof next === 'function' ? next(searchPageSize) : next
    searchParams.set('limit', `${nextValue}`)
    setSearchParams(searchParams)
  }

  const ConnectionFilter = () => {
    const updateSelection = (state: string, exclusive: boolean) => {
      const next = exclusive
        ? [state]
        : stateFilter.includes(state)
          ? stateFilter.filter((item) => item !== state)
          : [...stateFilter, state]

      setStateFilter(
        next.length === 0 ? ['online', 'offline', 'unknown'] : next,
      )
    }

    const Option = ({ state }: { state: string }) => (
      <span
        className={`w-6 cursor-pointer border rounded-lg m-2 ${stateColors[state]} ${stateFilter.includes(state) ? 'border-blue-400' : 'opacity-30 border-grey-900'}`}
        onClick={(e) => updateSelection(state, e.metaKey)}
      >
        <Tooltip
          trigger={
            <span>
              <CircleFilledIcon />
            </span>
          }
        >
          {state}
        </Tooltip>
      </span>
    )

    return (
      <div className="relative">
        <div className="bg-white border rounded shadow-lg">
          <div className="flex items-center">
            <Option state="online" />
            <Option state="offline" />
            <Option state="unknown" />
          </div>
        </div>
      </div>
    )
  }

  const toOvenData = (
    data: ThingHistory[],
    page: number,
    limit: number,
  ): OvenData[] => {
    if (activeTab === 'favorites') {
      const namedSearch = search.match(/"(.+)"?/)
      // Ignore page size here, give back all matching names
      if (namedSearch) {
        return data
          .filter(
            (item) =>
              ovenNames[item.deviceID] &&
              ovenNames[item.deviceID]
                .toLocaleLowerCase()
                .includes(namedSearch[1].toLocaleLowerCase()),
          )
          .map((item) => ({
            thingName: item.deviceID,
            favorited: item.favorited,
            viewedAtMs: item.viewedAtMs,
          }))
      }
    }

    return data.slice(page * limit, (page + 1) * limit).map((item) => ({
      thingName: item.deviceID,
      favorited: item.favorited,
      viewedAtMs: item.viewedAtMs,
    }))
  }

  const invalidate = () => {}

  const matchedSearch = search.match(/^(.*?)\s*(?:\+group:(.+?))?$/) || []

  const searchString = matchedSearch[1]?.trim() || ''
  const searchGroup = matchedSearch[2]?.trim() || ''

  return (
    <div>
      <OvenSearch
        onSearch={setSearch}
        search={searchString}
        searchGroup={activeTab === 'search' ? searchGroup : ''}
        searchKey={searchKey}
      />

      <div className="bg-slate-50 p-2 shadow-lg rounded-md relative">
        {activeTab === 'search' && (
          <div className="absolute xs:relative right-4 top-1">
            <ConnectionFilter />
          </div>
        )}
        <TabinatedRenderer
          activeTab={activeTab}
          setActiveTab={updateTab}
          tabs={[
            {
              key: 'history',
              label: 'History',
              content: (
                <OvenList
                  givenNames={ovenNames}
                  invalidate={invalidate}
                  isLoading={historyLoading}
                  isPreviousData={historyLoading}
                  limit={historyPageSize}
                  ovens={toOvenData(
                    allHistory || [],
                    historyPage,
                    historyPageSize,
                  )}
                  page={historyPage}
                  setLimit={setHistoryPageSize}
                  setPage={setHistoryPage}
                  setSearch={setSearch}
                  tab="history"
                  total={allHistory?.length || 0}
                />
              ),
            },
            {
              key: 'search',
              label: 'Search',
              content: (
                <OvenList
                  givenNames={ovenNames}
                  invalidate={invalidate}
                  isLoading={isLoading}
                  isPreviousData={isLoading}
                  limit={searchPageSize}
                  ovens={searchResults?.ovens || []}
                  page={searchPage}
                  setLimit={setSearchPageSize}
                  setPage={setSearchPage}
                  setSearch={setSearch}
                  tab="search"
                  total={searchResults?.total || 0}
                />
              ),
            },
            {
              key: 'favorites',
              label: 'Named Ovens',
              content: (
                <OvenList
                  givenNames={ovenNames}
                  invalidate={invalidate}
                  isLoading={globalFavoritesLoading}
                  isPreviousData={globalFavoritesLoading}
                  limit={globalFavoritePageSize}
                  ovens={toOvenData(
                    namedOvens,
                    globalFavoritePage,
                    globalFavoritePageSize,
                  )}
                  page={globalFavoritePage}
                  setLimit={setGlobalFavoritePageSize}
                  setPage={setGlobalFavoritePage}
                  setSearch={setSearch}
                  tab="favorites"
                  total={namedOvens.length}
                />
              ),
            },
            {
              key: 'my-favorites',
              label: 'My Favorites',
              content: (
                <OvenList
                  givenNames={ovenNames}
                  invalidate={invalidate}
                  isLoading={favoritesLoading}
                  isPreviousData={favoritesLoading}
                  limit={myFavoritePageSize}
                  ovens={toOvenData(
                    myFavorites,
                    myFavoritePage,
                    myFavoritePageSize,
                  )}
                  page={myFavoritePage}
                  setLimit={setMyFavoritePageSize}
                  setPage={setMyFavoritePage}
                  setSearch={setSearch}
                  tab="my-favorites"
                  total={myFavorites.length}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default OvensPage
